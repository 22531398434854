<template>
	<!-- 工作台 -->
	<div class="mod-config">
		<el-row>
			<el-card>
				<div slot="header" class="bd">项目信息
					<!-- <el-button v-preventReClick @click="settingProjectKind()" type="primary" plain icon="el-icon-edit"
						circle size="mini" style="margin-left: 1450px;"></el-button> -->
				</div>
				<el-form :model="infoName" ref="infoName" label-width="135px" :inline="true" class="border-form">
					<el-row class="set_border">
						<el-col :span="8" class="col-set_border">
							<el-form-item label="工作控制号" class="item">
								{{ infoName.workNo }}
							</el-form-item>
						</el-col>
						<el-col :span="16">
							<el-form-item label="项目名称" class="item">
								{{ infoName.projectName }}
							</el-form-item>
						</el-col>
					</el-row>
					<el-row class="set_border">
						<el-col :span="8" class="col-set_border">
							<el-form-item label="委托单位" class="item">
								{{ infoName.projectEntrust }}
							</el-form-item>
						</el-col>
						<el-col :span="16">
							<el-form-item label="承包单位" class="item">
								{{ infoName.contractCompany }}
							</el-form-item>
						</el-col>
					</el-row>
					<el-row class="set_border">
						<el-col :span="8" class="col-set_border">
							<el-form-item label="设计单位" class="item">
								{{ infoName.projectDesignCompany }}
							</el-form-item>
						</el-col>
						<el-col :span="16">
							<el-form-item label="施工单位" class="item">
								{{ infoName.projectConstructionCompany }}
							</el-form-item>
						</el-col>
					</el-row>
					<el-row class="set_border">
						<el-col :span="8" class="col-set_border">
							<el-form-item label="开工时间" class="item">
								{{ infoName.projectBeginTime }}
							</el-form-item>
						</el-col>
						<el-col :span="16">
							<el-form-item label="完工时间" class="item">
								{{ infoName.projectFinishTime }}
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-card>
		</el-row>
		<el-row style="margin-top: 10px;">
			<el-col :span="11">
				<el-card class="">
					<div slot="header" class="bd">标的物
						<i class="el-icon-d-arrow-right" style="float:right" @click="showMoreSubject()"></i>
					</div>
					<el-col :span="11" v-for="(item, index) in $store.state.common.navBarSubjectList"
						style="margin-left: 20px;margin-bottom: 15px;">
						{{ item.projectNo }}
						<span style="margin-left: 10px;">{{ item.displayName }}</span>
					</el-col>
				</el-card>
			</el-col>
			<el-col :span="13">
				<el-card class="set_card" style="margin-left: 10px;">
					<div slot="header" class="bd">项目负责人
						<i class="el-icon-d-arrow-right" style="float:right" @click="showMorePerson()"></i>
					</div>
					<span style="margin-left: 20px;"> 项目总监 </span>
					<span style="margin-left: 30px;"> {{ infoName.projectDirector }}</span>
				</el-card>
			</el-col>
		</el-row>
		<el-row style="margin-top: 10px;">
			<el-col :span="11">
				<el-card class="">
					<div slot="header" class="bd">未封闭工作</div>
					<el-row>
						<el-col :span="6">
							<div class="roundBox roundBox01">
								<div @click="moreInspection()">{{ noCloseCount.problemSource }}</div>
							</div>
							<div class="roundText">巡视</div>
						</el-col>
						<el-col :span="6">
							<div class="roundBox roundBox02">
								<div @click="moreInformation()">{{ noCloseCount.problemState }}</div>
							</div>
							<div class="roundText">报验</div>
						</el-col>
						<el-col :span="6">
							<div class="roundBox roundBox03">
								<div @click="moreContact()">{{ noCloseCount.contact }}</div>
							</div>
							<div class="roundText">监理联系单</div>
						</el-col>
						<el-col :span="6">
							<div class="roundBox roundBox04">
								<div @click="moreNotice()">{{ noCloseCount.notice }}</div>
							</div>
							<div class="roundText">监理通知单</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :span="13">
				<el-card class="set_card" style="margin-left: 10px;">
					<div slot="header" class="bd">项目相关工作</div>
					<el-row>
						<el-col :span="3" style="margin-left: 40px;">
							<div class="roundBox roundBox05">
								<div @click="moreStation()">{{ infoCount.monitor }}</div>
							</div>
							<div class="roundText">旁站</div>
						</el-col>
						<el-col :span="3">
							<div class="roundBox roundBox04">
								<div @click="moreMeet()">{{ infoCount.meet }}</div>
							</div>
							<div class="roundText">会议</div>
						</el-col>
						<el-col :span="3">
							<div class="roundBox roundBox07">
								<div @click="moreExamination()">{{ infoCount.exa }}</div>
							</div>
							<div class="roundText">文件审查</div>
						</el-col>
						<el-col :span="3">
							<div class="roundBox roundBox06">
								<div @click="moreContact()">{{ infoCount.contact }}</div>
							</div>
							<div class="roundText">监理联系单</div>
						</el-col>
						<el-col :span="3">
							<div class="roundBox roundBox03">
								<div @click="moreNotice()">{{ infoCount.notice }}</div>
							</div>
							<div class="roundText">监理通知单</div>
						</el-col>
						<el-col :span="3">
							<div class="roundBox roundBox01">
								<div @click="moreWeekly()">{{ infoCount.week }}</div>
							</div>
							<div class="roundText">监理周报</div>
						</el-col>
						<el-col :span="3">
							<div class="roundBox roundBox02">
								<div @click="moreMonthly()">{{ infoCount.month }}</div>
							</div>
							<div class="roundText">监理月报</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
		<el-row style="margin-top: 10px;">
			<el-col :span="24">
				<el-card class="">
					<div slot="header" class="bd">进度节点
						<i class="el-icon-d-arrow-right" style="float:right" @click="showMoreNode()"></i>
					</div>
					<!-- <div class="node-container" v-for="(node) in nodeList" style="margin-top: 10px;">
						<div class="node-left-container">{{ node.projectNo }}</div>
						<div class="node-right-container">
							<div class="node-right-container-row">
								<div class="node-content-title1">日期</div>
								<div class="node-content-body1" v-for="(item, index) in node.dataDate" v-if="index < node.dataDate.length - 1">{{ item }}</div>
								<div class="node-content-body4" v-for="(item, index) in node.dataDate" v-if="index == node.dataDate.length - 1">{{ item }}</div>
							</div>
							<div class="node-right-container-row">
								<div class="node-content-title2">计划</div>
								<div class="node-content-body" v-for="(item, index) in node.dataPlan" v-if="index < node.dataPlan.length - 1">{{ item }}</div>
								<div class="node-content-body5" v-for="(item, index) in node.dataPlan" v-if="index == node.dataPlan.length - 1">{{ item }}</div>
							</div>
							<div class="node-right-container-row" v-if="node.dataChange.length > 0">
								<div class="node-content-title-container">
									<div class="node-content-title-container-left">
										变更
									</div>
									<div class="node-content-title-container-right">
										<div class="node-content-title-container-right-row"
											v-for="(item) in node.dataChange">
											<div class="node-content-body" v-for="(item2, index) in item" v-if="index < item.length - 1">{{ item2 }}</div>
											<div class="node-content-body5" v-for="(item2, index) in item" v-if="index == item.length - 1">{{ item2 }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="node-right-container-row">
								<div class="node-content-title3">实际</div>
								<div class="node-content-body3" v-for="(item, index) in node.dataTime" v-if="index < node.dataTime.length - 1">{{ item }}</div>
								<div class="node-content-body6" v-for="(item, index) in node.dataTime" v-if="index == node.dataTime.length - 1">{{ item }}</div>
							</div>
						</div>
					</div> -->
					<el-table max-height="600" :row-class-name="tableRowClassName" :span-method="objectSpanMethod"
						ref="dataListTable" class="dataListTable" border :data="nodeList"
						header-align="center" header-cell-class-name="dataListTHeader"
						:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;">
						<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '0'"
							:label="v.nodeName" :prop="v.nodeId" align="center" width="130px">
								<template slot-scope="scope">
								<span style="font-weight:bolder">
									{{scope.row.subjectName }}
								</span>
								</template>
						</el-table-column>
						<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && v.nodeId === '1'"
							:label="v.nodeName" :prop="v.nodeId" align="center" width="60px">
								<template slot-scope="scope">
								<span style="font-weight:bolder">
									{{scope.row.title}}
								</span>
								</template>
						</el-table-column>
						<el-table-column v-for="(v, idx) in headers" :key="idx" v-if="headers != undefined && headers.length > 0 && idx > 1"
							:label="v.nodeName" :prop="v.nodeId" align="center">
								<template slot-scope="scope">
								<span>
									{{scope.row.contentList[idx-2].colValue }}
								</span>
								</template>
						</el-table-column>
						<el-table-column align="left" label="" v-if="headers != undefined && headers.length == 2">
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
		<el-row style="margin-top: 10px;">
			<el-col :span="11">
				<el-card class="">
					<div slot="header" class="bd">质量巡检整改</div>
					<el-row v-for="(inspection, index) in inspectionList" v-model="inspectionList">
						<el-col :span="24" style="font-weight: bolder;font-size: 16px;margin-bottom: 10px;"> {{
							inspection.projectNo }}</el-col>
						<el-col :span="24">
							<el-table ref="workbenchList" class="workbenchList" :data="inspection.detailDtoList"
								header-align="center" :cell-style="{ padding: '0px' }"
								header-cell-class-name="dataListTHeader" style="margin-bottom: 20px;">
								<el-table-column label="专业" prop="majorName" width="" align="center">
								</el-table-column>
								<el-table-column label="提出整改项" prop="applyNum" width="" align="center">
								</el-table-column>
								<el-table-column label="整改完成项" prop="alreadyModifyNum" width="" align="center">
								</el-table-column>
								<el-table-column label="未完成项" prop="notFinishNum" width="" align="center">
									<template slot-scope="scope">
										<div style="color: red;"> {{ scope.row.notFinishNum }}</div>
									</template>
								</el-table-column>
								<el-table-column label="完成率" prop="finishRate" width="" align="center">
								</el-table-column>
							</el-table>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :span="13">
				<el-card class="" style="margin-left: 10px;">
					<div slot="header" class="bd">质量报验</div>
					<el-row v-for="(testrecord, index) in testRecordList" v-model="testRecordList">
						<el-col :span="24" style="font-weight: bolder;font-size: 16px;margin-bottom: 10px;"> {{
							testrecord.projectNo }}</el-col>
						<el-col :span="24">
							<el-table ref="workbenchList" class="workbenchList" :data="testrecord.detailDtoList"
								header-align="center" :cell-style="{ padding: '0px' }"
								header-cell-class-name="dataListTHeader" style="margin-bottom: 20px;">
								<el-table-column label="专业" prop="majorName" width="" align="center">
								</el-table-column>
								<el-table-column label="报验" prop="testRecordNum" width="" align="center">
								</el-table-column>
								<el-table-column label="接受" prop="testConclusionNum" width="" align="center">
								</el-table-column>
								<el-table-column label="有条件接受" prop="testConclusionWhereNum" width="" align="center">
								</el-table-column>
								<el-table-column label="修正后复检" prop="recheckAfterCorrectionNum" width="" align="center">
								</el-table-column>
								<el-table-column label="不接受" prop="testConclusionRefuseNum" width="" align="center">
								</el-table-column>
								<el-table-column label="合格率" prop="testConclusionRate" width="" align="center">
								</el-table-column>
							</el-table>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
		<project-kind-setting v-if="projectKindSettingVisible" ref="projectKindSetting"
			@refreshDataList="getInfoName()"></project-kind-setting>
	</div>
</template>

<script>
import $common from "@/utils/common.js"
import ProjectKindSetting from './project-kind-setting'
export default {
	data() {
		return {
			showNodeMore: false,
			showPersonMore: false,
			showPersonSet: false,
			infoList: [],
			noCloseCount: {},
			infoCount: {},
			infoName: {},
			infoRemind: {},
			category: {},
			jobList: [],
			inspectorInfo: {},
			setingPersonVisible: false,
			projectKindSettingVisible: false,
			helpFileUrl: 'http://cs.ccsi.com.cn/upload/help/helpFile.pdf',
			menuList: [],
			subjectList: [],
			inspectionList: [],
			testRecordList: [],
			nodeList: [],
			headers:[]
		};
	},
	components: {
		ProjectKindSetting
	},
	mounted() {
		this.menuList = $common.getItem("menuList")
		this.workNo = $common.getItem("workNo")
		this.getSubjectDropDown()
	},
	methods: {
		getProjectKind(kind) {
			if (kind === '1') {
				return '船舶监理'
			} else if (kind === '2') {
				return '设备监理'
			}
			return ''
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					this.$store.commit('common/updateSubjectList', this.subjectList)
					if (this.subjectList.length != undefined && this.subjectList.length > 0) {
						if ($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined) {
							$common.setItem("subjectNo", this.subjectList[0].displayValue);
							this.$store.commit('common/updateSelectSubjectNo', this.subjectNo)
						}
					}
					this.$emit('subMenuIndex', 0)
					this.showNodeMore = $common.isAuth('workbench:node.more');
					this.showPersonMore = $common.isAuth('workbench:person.more');
					this.showPersonSet = $common.isAuth('workbench:person.set');
					// this.getInfo();
					this.getInfoCount();
					this.getInfoName();
					this.getInfoRemind();
					this.selectCategory();
					this.selectJob();
					this.getNoCloseCount();
					this.getTestRecordList();
					this.getInspectionList();
					this.refreshNodeRecords();
					// this.getNodeInfoList();
				}
				// }
			});
		},
		getSubjectMenuIndex() {
			let rtn = 0
			this.menuList[0].list.forEach(function (item, idx) {
				if (item.routerName === '/subjectList') {
					rtn = idx
				}
			})
			return rtn
		},
		showHelpFile() {
			window.open(this.helpFileUrl);
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 50;
			return { height: height + 'px' }
		},
		projectUserListHeight() {
			var height = this.$store.state.common.documentClientHeight - 670;
			return { height: height + 'px' }
		},
		// getInfo() {
		// 	this.$http({
		// 		//   url:  this.$store.state.httpUrl + "/index/projectInfo/"+$common.getItem("workNo"),
		// 		url: this.$store.state.httpUrl + "/business/nodeinfo/getNodeInfo/" + $common.getItem("workNo"),
		// 		method: "get",
		// 		params: {},
		// 	}).then(({ data }) => {
		// 		if (data && data.resultCode === 200) {
		// 			this.infoList = data.body;
		// 		}
		// 	});
		// },
		settingProjectKind() {
			this.projectKindSettingVisible = true
			this.$nextTick(() => {
				this.$refs.projectKindSetting.init()
			})
		},
		setingPersonHandle(id) {
			this.setingPersonVisible = true
			this.$nextTick(() => {
				this.$refs.setingPerson.init(id);
			})
		},
		goPage(type) {
			let _indexList = [];
			if (type == 1) {
				this.$router.push("/nodeList");
				_indexList = $common.getMenuIndex("/nodeList");
			} else {
				this.$router.push("/personnelCommun");
				_indexList = $common.getMenuIndex("/personnelCommun");
			}
			this.$parent.setMenuAllIndex(_indexList[0], _indexList[1]);
		},
		getNoCloseCount() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/noCloseCount/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.noCloseCount = data.body;
				}
			});
		},
		getInspectionList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/getInspectionList/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.inspectionList = data.body;
				}
			});
		},
		getTestRecordList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/getTestRecordList/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.testRecordList = data.body;
				}
			});
		},
		refreshNodeRecords() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/refreshNodeRecords/" + $common.getItem("workNo"),
				method: 'post',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.getNodeInfoList();	
				}
			})
		},
		// getNodeInfoList() {
		// 	this.$http({
		// 		url: this.$store.state.httpUrl + "/business/nodeinfo/getNodeInfoList/" + $common.getItem("workNo"),
		// 		method: "get",
		// 		params: {},
		// 	}).then(({ data }) => {
		// 		if (data && data.resultCode === 200) {
		// 			this.nodeList = data.body;
		// 		}
		// 	});
		// },
		// 获取进度节点数据
		 getNodeInfoList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectprogressnoderecord/list/" + $common.getItem("workNo"),
				method: 'get',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
				this.nodeList = data.body.records;
				this.headers = data.body.headers;
				}
			})
		},
		getInfoCount() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/infoCount/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.infoCount = data.body;
				}
			});
		},
		getInfoName() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/infoName/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.infoName = data.body;
					this.infoName.projectEntrust = this.infoName.projectEntrust.replaceAll(";", "<br/>");
					this.infoName.projectDesignCompany = this.infoName.projectDesignCompany.replaceAll(";", "<br/>");
					this.infoName.projectConstructionCompany = this.infoName.projectConstructionCompany.replaceAll(";", "<br/>");
				}
			});
		},
		getInfoRemind() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/infoRemind/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.infoRemind = data.body;
				}
			});
		},
		selectCategory() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/selectCategory?workNo=" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.category = data.body;
				}
			});
		},
		selectJob() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/selectJob?workNo=" + $common.getItem("workNo"),
				method: "POST",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.jobList = data.body.personList;
					this.inspectorInfo = data.body.inspectorInfo;
				}
			});
		},
		showMoreSubject() {
			sessionStorage.setItem('menuIndex', 0)
			sessionStorage.setItem('subMenuIndex', 2)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'subjectList' });
		},
		showMorePerson() {
			sessionStorage.setItem('menuIndex', 0)
			sessionStorage.setItem('subMenuIndex', 1)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'personnelCommun' });
		},
		showMoreNode() {
			sessionStorage.setItem('menuIndex', 3)
			sessionStorage.setItem('subMenuIndex', 2)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'projectProgressRecordList' });
		},
		moreInspection() {
			sessionStorage.setItem('menuIndex', 1)
			sessionStorage.setItem('subMenuIndex', 0)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'patrolList', query: { type: 1 } });
		},
		moreInformation() {
			sessionStorage.setItem('menuIndex', 1)
			sessionStorage.setItem('subMenuIndex', 1)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'information', query: { type: 1 } });
		},
		moreContact() {
			sessionStorage.setItem('menuIndex', 6)
			sessionStorage.setItem('subMenuIndex', 1)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'contactdocument', query: { type: '01' } });
		},
		moreNotice() {
			sessionStorage.setItem('menuIndex', 6)
			sessionStorage.setItem('subMenuIndex', 2)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'contactdocument', query: { type: '02' } });
		},
		moreStation() {
			sessionStorage.setItem('menuIndex', 1)
			sessionStorage.setItem('subMenuIndex', 2)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'stationList', query: { type: 1 } });
		},
		moreExamination() {
			sessionStorage.setItem('menuIndex', 6)
			sessionStorage.setItem('subMenuIndex', 0)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'examination' });
		},
		moreWeekly() {
			sessionStorage.setItem('menuIndex', 6)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			// 判断周报类型后跳转至对应页面
			if(this.infoName.weeklyReportTypeId === 'WEEK01' || this.infoName.weeklyReportTypeId === 'WEEK03') {
				sessionStorage.setItem('subMenuIndex', 4)
				this.$router.push({ name: 'weekly' });
			} else {
				sessionStorage.setItem('subMenuIndex', 5)
				this.$router.push({ name: 'projectWeekly' });
			}
		},
		moreMonthly() {
			sessionStorage.setItem('menuIndex', 6)
			sessionStorage.setItem('subMenuIndex', 6)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'monthly' });
		},
		moreMeet() {
			sessionStorage.setItem('menuIndex', 7)
			sessionStorage.setItem('subMenuIndex', 0)
			sessionStorage.setItem('sessionFlg', true)
			sessionStorage.setItem('sessionFlg2', true)
			this.$cookie.set('showSidebarFlag', false)
			this.$router.push({ name: 'meetingList' });
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableScnenRowClassName({ row, rowIndex });
		},
		flitterSubjectNo (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
				spanOneArr.push(1);
				} else {
				//name 修改
				if (item.subjectName === arr[index - 1].subjectName) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		flitterData (arr) {
			let spanOneArr = []
			let concatOne = 0
			arr.forEach((item, index) => {
				if (index === 0) {
					spanOneArr.push(1);
				} else {
				//name 修改
				if (item.title === arr[index - 1].title) { //第一列需合并相同内容的判断条件
					spanOneArr[concatOne] += 1;
					spanOneArr.push(0);
				} else {
					spanOneArr.push(1);
					concatOne = index;
				};
				}
			});
			return {
				one: spanOneArr,
			}
		},
		objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				// this.tableData  修改
				const _row = (this.flitterSubjectNo(this.nodeList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			} else if(columnIndex === 1){
				// this.tableData  修改
				const _row = (this.flitterData(this.nodeList).one)[rowIndex];
				const _col = _row > 0 ? 1 : 0;
				return {
				rowspan: _row,
				colspan: _col
				};
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

.roundText {
	font-size: 16px;
	color: #1c1c1c;
	margin-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}

.roundBox {
	width: 78px;
	height: 78px;
	margin: 20px auto;
	border-radius: 39px;
	box-shadow: 0 0 12px 0 rgb(74 133 253 / 30%);
	padding: 8px;
	box-sizing: border-box;
	cursor: pointer;

	div {
		width: 60px;
		height: 60px;
		border-radius: 30px;
		line-height: 60px;
		text-align: center;
		font-size: 26px;
		color: #ffffff;
		background: linear-gradient(to right, #4a85fe 0%, #78a4ff 100%);
	}
}

.roundBox02 {
	box-shadow: 0 0 12px 0 rgb(255 185 70 / 30%);

	div {
		background: linear-gradient(to right, #ffb946 0%, #ffc86e 100%);
	}
}

.roundBox03 {
	box-shadow: 0 0 12px 0 rgb(208 97 204 / 30%);

	div {
		background: linear-gradient(to right, #78c5e4 0%, #72cfdb 100%);
	}
}

.roundBox04 {
	box-shadow: 0 0 12px 0 rgb(230 57 70 / 30%);

	div {
		background: linear-gradient(to right, #e63946 0%, #f3646f 100%);
	}
}


.roundBox05 {
	box-shadow: 0 0 12px 0 rgb(208 97 204 / 30%);

	div {
		background: linear-gradient(to right, #8ad8a8 0%, #9fdf84 100%);
	}
}

.roundBox06 {
	box-shadow: 0 0 12px 0 rgb(230 57 70 / 30%);

	div {
		background: linear-gradient(to right, #7ea3da 0%, rgb(65, 151, 231) 100%);
	}
}

.roundBox07 {
	box-shadow: 0 0 12px 0 rgb(230 57 70 / 30%);

	div {
		background: linear-gradient(to right, #da7ed2 0%, rgb(231, 65, 231) 100%);
	}
}

.bd {
	font-weight: bolder !important;
	font-size: 16px;
	color: #00428E;
	// height: 36px;
}

.el-icon-d-arrow-right {
	font-size: 20px;
	color: rgb(58, 58, 190);
	font-weight: 800;
	cursor: pointer;
}

.node-container {
	display: flex;
	color: #1c1c1c;
}

.node-left-container {
	// border: 1px #DADADA solid;
	border-right: 0px;
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

.node-right-container {
	border-right: 1px #DADADA solid;
	border-bottom: 1px #DADADA solid;
	display: flex;
	flex-direction: column;
}

.node-right-container-row {
	display: flex;
}

.node-content-title1 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(211, 230, 248);
	height: 30px
}

.node-content-title2 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px
}

.node-content-title3 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(240, 240, 33);
	height: 30px
}

.node-content-body {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 120px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px
}

.node-content-body5 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 230px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px
}

.node-content-body1 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 120px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(211, 230, 248);
	height: 30px
}

.node-content-body4 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 230px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(211, 230, 248);
	height: 30px
}

.node-content-body3 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 120px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(240, 240, 33);
	height: 30px
}

.node-content-body6 {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 230px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(240, 240, 33);
	height: 30px
}

.node-content-title-container {
	display: flex;
}

.node-content-title-container-left {
	border: 1px #DADADA solid;
	border-right: 0px;
	border-bottom: 0px;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ebeae8;
}

.node-content-title-container-right {
	display: flex;
	flex-direction: column;
}

.node-content-title-container-right-row {
	display: flex;
	flex-direction: row;
	background-color: #ebeae8;
}
</style>

